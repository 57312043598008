import Box from '@mui/material/Box';
import Zoom from '@mui/material/Zoom';
import CircularProgress from '@mui/material/CircularProgress';

import OutlinedButton from "@/app/components/OutlinedButton";
import TextButton from "@/app/components/TextButton";
import LargeCheckbox from "@/app/components/LargeCheckbox";
import {useTheme} from "@mui/material";
import {useRef} from "react";
import {useTranslation} from "react-i18next";

const ImageCard = ({handleCheckFrameEvent, size, modelImg, isSelected}: any) => {
  const theme = useTheme();
  const imgRef = useRef(null);
  const checkedEvent = () => {
    handleCheckFrameEvent();
  }

  return (
    <Box sx={{
      marginRight: "20px",
      margin: 0,
    }}>
      <Box
        sx={{display: (modelImg.src != "") ? 'display' : 'none', padding: '0px', margin: '0px', color: "#FF0000", }}
      >
        <Box
          sx={{
            position: "relative", width: size.width,
            height: size.height, backgroundColor: "#AABBCC00",
          }}
        >
          <div
            style={{
              position: "absolute", top: "20px", left: "10px", width: "30px",
              height: "40px", backgroundColor: "#FF000000", color: "#FFFFFFFF", zIndex: "1001"
            }}>
            <Box>
              <LargeCheckbox
                checked={isSelected}
                onChange={checkedEvent}
              />
            </Box>
          </div>
          <Box
            onClick={checkedEvent}
            sx={{
              backgroundColor: isSelected ? theme.palette.secondary.main : 'transparent',
              boxShadow: isSelected ? 'rgba(0, 0, 0, 0.3) -12px 12px 12px 0px' : 'none',
              transition: '0.2s',
              position: 'relative',
              borderRadius: '4px',

              '&:before': {
                transition: '0.2s',
                zIndex: -9999,
                content: '""',
                display: 'block',
                width: '100%',
                height: '100%',
                background: isSelected ? '#fff' : 'transparent',
                position: 'absolute',
                transform: isSelected ? 'translate(-12px, 12px)' : 'none',
                borderRadius: '4px',
              }
            }}
          >
            <img
              src={modelImg.src} alt="Captured"
              ref={imgRef}
              style={{
                borderRadius: "4px", margin: 0, objectFit: "fill", width: '100%', transition: '0.2s',
                transform: isSelected ? 'translate(12px, -12px)' : 'none',
                boxShadow: isSelected ? '-12px 12px 12px 0px rgba(0, 0, 0, 0.3)' : 'none',
                backgroundColor: isSelected ? theme.palette.secondary.main : 'transparent',
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
};

export default function SubjectFrames({
                                        subjectImages, handleContinue, handleRetake, selectedSubjectImageIndex,
                                        setSelectedSubjectImageIndex
                                      }: any) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{display: "flex", flexDirection: "column", position: "relative", width: '100vw', height: '100vh',}}
    >
      <Box
        sx={{
          color: '#fff',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Zoom in={!subjectImages.length}>
          <CircularProgress
            sx={{
              color: '#fff',
              'circle': {
                strokeWidth: 1,
              }
            }}
            size={400}
          />
        </Zoom>
      </Box>

      <Zoom in={subjectImages.length === 2}>
        <Box sx={{
          position: "absolute",
          top: '220px',
          display: "flex",
          flexDirection: "column",
          width: '100%'
        }}>
          <Box
            sx={{
              fontSize: "3.6rem",
              color: '#fff',
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: '2px',
              marginBottom: '26px',
            }}
          >
            {t('chooseYourPicture')}
          </Box>

          <Box
            sx={{
              width: '800px',
              margin: '0 auto',
              backgroundColor: "transparent",
              display: "flex",
              // flexDirection: "column",
              // flexWrap: "wrap",
              justifyContent: 'space-between',
              gap: '60px'
            }}
          >
            {subjectImages.map((subjectImage: string, index: number) => (
              <Zoom in={!!subjectImage} key={index}>
                <Box>
                  {!!subjectImage &&
										<ImageCard
											handleCheckFrameEvent={() => setSelectedSubjectImageIndex(selectedSubjectImageIndex === index ? -1 : index)}
											modelImg={{src: subjectImage}}
											size={{width: '100%', height: 'auto'}}
                      // size={{width: containerWidth, height: containerHeight}}
											isSelected={selectedSubjectImageIndex === index}
                    />
                  }
                </Box>
              </Zoom>
            ))}
          </Box>

          <Box
            sx={{marginTop: '140px'}}
            className="flex items-center mb-5 justify-center"
          >

            <OutlinedButton
              sx={{
                width: '864px'
              }}
              disabled={selectedSubjectImageIndex === -1}
              onClick={handleContinue}
            >
              {t('continueToProductsList')}
            </OutlinedButton>
          </Box>

          <Box
            className="flex items-center justify-center"
          >
            <TextButton
              sx={{
                textDecoration: 'underline !important',
                width: '864px'
              }}
              onClick={handleRetake}
            >
              {t('retakePicture')}
            </TextButton>
          </Box>
        </Box>
      </Zoom>
    </Box>
  )
}