import {useEffect, useRef, useState} from "react";
import TakePhotoCountDown from "./TakePhotoCountDown";
import VirtualCamera from "./VirtualCamera";
import StoreLayout from "@/app/layouts/StoreLayout";
import TextButton from "@/app/components/TextButton";
import {logout} from "@/app/common/api";
import {useTranslation} from "react-i18next";

// Define an enum to represent the steps
enum VirtualSteps {
  StepCountDown,
  StepTakePictures,
  StepEditPictures
}

export default function VirtualMainView() {
  const [virtualMainStep, setVirtualMainStep] = useState<VirtualSteps>(VirtualSteps.StepCountDown);
  const [incLogoutHiddenCounter, setIncLogoutHiddenCounter] = useState(0);
  const shouldForceUnload = useRef(false);
  const [subjectImages, setImageFrames] = useState<string[]>([])
  const {t} = useTranslation();

  useEffect(() => {
    const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
      if(shouldForceUnload.current || process.env.NODE_ENV === 'development') return;

      e.preventDefault();
    }
    window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    }
  }, [])

  useEffect(() => {
    const resetCounterTimeout = setTimeout(() => {
      setIncLogoutHiddenCounter(0)
    }, 1000 * 20)

    if(incLogoutHiddenCounter >= 10) {
      shouldForceUnload.current = true;
      logout()
      window.location.reload()
    }

    return () => {
      clearTimeout(resetCounterTimeout)
    }
  }, [incLogoutHiddenCounter])

  const handleHiddenIncCounter = () => {
    setIncLogoutHiddenCounter(prev => prev+1)
  }

  function handleCloseCountDown(base64SubjectPhotos: string[]) {
    setImageFrames(base64SubjectPhotos)
    setVirtualMainStep(VirtualSteps.StepTakePictures);
  }

  function handleVirtualCameraEventFinish() {
    setVirtualMainStep(VirtualSteps.StepEditPictures);
  }

  function handleRetakePicture() {
    setImageFrames([])
    setVirtualMainStep(VirtualSteps.StepCountDown);
  }

  // const handleShortcutFlow = () => {
  //   setIsShortcutFlow(true)
  //   handleVirtualCameraEventFinish()
  // }

  const goHome = () => {
    if (virtualMainStep == VirtualSteps.StepCountDown) return;

    const answer = window.confirm(t('reshotCacheClearingWarning'));
    if (!answer) return;

    handleRetakePicture()
  }

  if (virtualMainStep == VirtualSteps.StepCountDown) return (
    <StoreLayout onLogoClick={goHome} hasLanguageMenu showTermsAndConditions onHiddenBoxClick={handleHiddenIncCounter}>
      <TakePhotoCountDown countDownOpen={virtualMainStep == 0} handleSubjectPhotos={handleCloseCountDown}/>
    </StoreLayout>
  )

  if (virtualMainStep >= VirtualSteps.StepTakePictures) {
    return (
      <StoreLayout onLogoClick={goHome}>
        <VirtualCamera
          subjectImages={subjectImages}
          handleTakePictureFinish={handleVirtualCameraEventFinish}
          handleRetakePicture={handleRetakePicture}
          goHome={goHome}
        />
      </StoreLayout>
    )
  }
  // return (
  //   <StoreLayout>
  //       {/*// @ts-ignore*/}
  //     {virtualMainStep == VirtualSteps.StepCountDown && <TakePhotoCountDown countDownOpen={virtualMainStep == 0} size={wSize} handleClose={handleCloseCountDown} />}
  //     {virtualMainStep >= VirtualSteps.StepTakePictures && <VirtualCamera size={wSize}
  // 																																				handleTakePictureFinish={handleVirtualCameraEventFinish}
  // 																																				handleRetakePicture={handleRetakePicture}/>}
  //     {/* {virtualMainStep == VirtualSteps.StepEditPictures && <ToggleButtonList />} */}
  //   </StoreLayout>
  // )
}