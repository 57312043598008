import React, {useEffect, useState} from 'react';
import {
  Box, Zoom, useTheme, IconButton
} from '@mui/material';
import '../../app/globals.css';
import Checkroom from "@mui/icons-material/Checkroom";
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import Male from '@mui/icons-material/Male';
import Female from '@mui/icons-material/Female';
import PhotoCameraOutlined from '@mui/icons-material/PhotoCameraOutlined';
import {useTranslation} from "react-i18next";

const imgHeight = 56;
const imgWidth = 56;

export const ColorsBox = ({colorData, isSelected, onClick, width = '60px', height = '60px'}: any) => {
  return (
    <Box onClick={() => onClick?.(colorData)} id={colorData.color} sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      alignItems: 'center',
      marginBottom: '10px',
      pb: '22px',
      pt: '6px',
      // todo replace with var later
      backgroundColor: (theme: any) => isSelected ? 'rgba(46, 142, 162,0.5)' : 'transparent',
      borderRadius: isSelected ? '10px' : 'none',
    }}>
      <Box sx={{flex: 1, fontSize: '2rem', marginBottom: '10px', fontWeight: 900}}>
        {colorData.color}
      </Box>
      <Box sx={{
        width,
        height,
        borderRadius: '4px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        flexShrink: 0,
      }}>
        {colorData.hex.map((color: string) => <Box key={color} sx={{
            flex: 1, flexShrink: 0,
            backgroundColor: color
          }}/>
        )}
      </Box>

    </Box>

  );
}

export default function ToggleButtonList({
                                           onPhotoRetake,
                                           onProductsListClick,

                                           isWishListOpen,
                                           isProductsListOpen,
                                           tryOnTaskResults,
                                           onTryOnGalleryClick,

                                           wishList,
                                           onWishListClick,

                                           colors,
                                           color,
                                           setColor,

                                           itemTypes,
                                           itemType,
                                           setItemType,

                                           genders,
                                           setGender,
                                           gender,
                                         }: any) {
  const [selectedButton, setSelectedButton] = useState<string | null>(null);
  const theme = useTheme();
  const {t} = useTranslation();

  const handleButtonClick = (id: string) => {
    if (selectedButton === id) {
      setSelectedButton(null); // If the same button is clicked again, reset the selection
    } else {
      setSelectedButton(id); // Otherwise, set the selected button
    }
  };


  const buttonData = [
    {
      text: t('gender'),
      imgSrc: 'https://storage.gonilabs.com/assets/slide_menu_gender.png',
      id: 'genderFilter',
      options: genders ?? [],
      optionText: (option: { value: string, label: string }) => option.label,
      hasIndication: () => false,
      onSelect: (selectedGender: { value: string, label: string }) => {
        setGender(selectedGender.value)
        setSelectedButton(null)
      },
      checkIsSelected: (option: { value: string, label: string }) => option.value.toLowerCase() === gender.toLowerCase(),
    },
    {
      text: t('category'),
      id: 'itemTypeFilter',
      imgSrc: 'https://storage.gonilabs.com/assets/slide_menu_category.png',
      options: itemTypes ?? [],
      hasIndication: () => !!itemType,
      onSelect: (selectedItemType: string) => {
        setItemType(itemType === selectedItemType ? '' : selectedItemType)
        setSelectedButton(null)
      },
      checkIsSelected: (option: string) => option.toLowerCase() === itemType.toLowerCase(),
    },
    {
      text: t('color'),
      id: 'colorFilter',
      imgSrc: 'https://storage.gonilabs.com/assets/slide_menu_color.png',
      options: colors ?? [],
      hasIndication: () => !!color,
      optionsRenderer: (options: any) => (
        <Box id="colorsSelectionContainer" sx={{height: '1024px', overflow: 'scroll', scrollbarWidth: 'none',}}>
          {options.map((option: any) => <ColorsBox
            onClick={(selectedColor: any) => {
              setColor(color === selectedColor.color ? '' : selectedColor.color)
              setSelectedButton(null)
            }}
            isSelected={option.color === color} key={option.color} colorData={option}/>)}
        </Box>
      )
    },
  ];

  const currentlySelectedButton = selectedButton ? buttonData.find(el => el.id === selectedButton) : null;


  useEffect(() => {
    if (currentlySelectedButton?.id === 'colorFilter' && color) {
      // todo add delay here, looks like it's working but not always
      // @ts-ignore
      document.getElementById(color)?.scrollIntoView();
    }
  }, [selectedButton]);


  return (
    <Zoom in>
      <Box className="button-container">
        {/* Display the selected button and hide others */}
        {selectedButton !== null && !!currentlySelectedButton?.options?.length ? (
          <Zoom in>
            <div className="selected-button">
              {/* Selected button moves to the top */}
              <button onClick={() => handleButtonClick(selectedButton)} className="button-item">
                <img src={currentlySelectedButton.imgSrc} alt={`Image ${selectedButton}`} width={imgWidth}
                     height={imgHeight} style={{marginBottom: '12px'}}/>
                <p style={{
                  fontSize: '2rem',
                  fontWeight: 900,
                  lineHeight: '2.2rem'
                }}>{currentlySelectedButton.text}</p>
              </button>
              <ul className="options-list">
                {/* Display options below the selected button */}
                {currentlySelectedButton?.optionsRenderer?.(currentlySelectedButton?.options) ?? currentlySelectedButton?.options?.map((option: any) => (
                  // @ts-ignore
                  <li key={currentlySelectedButton?.optionText?.(option) ?? option}>
                    <button
                      style={{
                        color: '#fff',
                        fontSize: '2rem',
                        fontWeight: 900,
                        lineHeight: '2.2rem',
                        background: currentlySelectedButton?.checkIsSelected?.(option) ? 'rgba(46, 142, 162, 0.5)' : 'transparent',
                        padding: '14px',
                        borderRadius: '10px',
                      }}
                      // @ts-ignore
                      onClick={() => currentlySelectedButton?.onSelect?.(option)}>{currentlySelectedButton?.optionText?.(option) ?? option}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </Zoom>
        ) : (
          // Display all buttons if no button is selected
          <>
            <Box onClick={onProductsListClick} sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center',
              padding: '30px 10px',
              position: 'relative'
            }}>

              <IconButton sx={{
                position: 'relative',
                marginBottom: '10px',
                svg: {
                  width: '66px',
                  height: '66px',
                  fill: isProductsListOpen ? theme.palette.secondary.main : '#fff',
                }
              }}>
                <ShoppingCart/>
              </IconButton>
              <Box
                sx={{
                  fontSize: '2rem',
                  lineHeight: '2.2rem',
                  fontWeight: 900,
                  textAlign: 'center',
                  maxWidth: '130px',
                  color: isProductsListOpen ? theme.palette.secondary.main : '#fff',
                }}>
                {t('products')}
              </Box>
            </Box>

            <Box onClick={onTryOnGalleryClick} sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center',
              padding: '30px 10px',
              position: 'relative'
            }}>

              <IconButton sx={{
                position: 'relative',
                marginBottom: '10px',
                ':after': tryOnTaskResults?.length ? {
                  width: '10px',
                  height: '10px',
                  border: '1px solid white',
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: '10px',
                  right: '0px',
                  borderRadius: '50%',
                  backgroundColor: 'red'
                } : '',
                svg: {
                  width: '66px',
                  height: '66px',
                }
              }}>
                <Checkroom/>
              </IconButton>
              <Box
                sx={{
                  fontSize: '2rem',
                  lineHeight: '2.2rem',
                  fontWeight: 900,
                  textAlign: 'center',
                  maxWidth: '130px'
                }}>
                {t('wardrobe')}
              </Box>
            </Box>
            {/**/}
            <Box onClick={onWishListClick} sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center',
              padding: '30px 10px',
              position: 'relative'
            }}>

              <IconButton sx={{
                position: 'relative',
                marginBottom: '10px',
                ':after': wishList?.length ? {
                  width: '10px',
                  height: '10px',
                  border: '1px solid white',
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: '10px',
                  right: '0px',
                  borderRadius: '50%',
                  backgroundColor: 'red'
                } : '',
                svg: {
                  transition: '0.2s',
                  fill: isWishListOpen ? theme.palette.secondary.main : '#fff',
                  width: '66px',
                  height: '66px',
                }
              }}>
                <FavoriteBorder/>
              </IconButton>
              <Box
                sx={{
                  transition: '0.2s',
                  color: isWishListOpen ? theme.palette.secondary.main : '#fff',
                  fontSize: '2rem',
                  lineHeight: '2.2rem',
                  fontWeight: 900,
                  textAlign: 'center',
                  maxWidth: '130px'
                }}>
                {t('likedList')}
              </Box>
            </Box>

            {buttonData.map((button) => (
              <button key={button.id} className="button-item" onClick={() => handleButtonClick(button.id)}>
                <Box
                  sx={{
                    position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center',
                    width: '100%',
                    '&:after': button?.hasIndication?.() ? {
                      width: '10px',
                      height: '10px',
                      content: "''",
                      display: 'block',
                      position: 'absolute',
                      top: '0px',
                      right: '32px',
                      borderRadius: '50%',
                      backgroundColor: 'red',
                    } : ''
                  }}
                >
                  <img src={button.imgSrc} alt={button.text} width={imgWidth} height={imgHeight}
                       style={{
                         transition: '0.2s',
                         marginBottom: '10px',
                         filter: button.id === selectedButton ? 'invert(47%) sepia(63%) saturate(455%) hue-rotate(143deg) brightness(91%) contrast(91%)' : 'none'
                       }}
                  />
                  <p style={{
                    transition: '0.2s',
                    color: button.id === selectedButton ? theme.palette.secondary.main : '#fff',
                    fontSize: '2rem',
                    fontWeight: 900,
                    lineHeight: '2.2rem'
                  }}>{button.text}</p>
                </Box>
              </button>
            ))}

            <Box onClick={onPhotoRetake} sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center',
              padding: '30px 10px',
              position: 'relative'
            }}>

              <IconButton sx={{
                position: 'relative',
                marginBottom: '10px',
                svg: {
                  width: '66px',
                  height: '66px',
                  fill: '#fff',
                }
              }}>
                <PhotoCameraOutlined/>
              </IconButton>
              <Box
                sx={{
                  fontSize: '2rem',
                  lineHeight: '2.2rem',
                  fontWeight: 900,
                  textAlign: 'center',
                  maxWidth: '130px',
                  color: '#fff',
                }}>
                {t('reshoot')}
              </Box>
            </Box>
          </>

        )}
      </Box>
    </Zoom>
  );
}
