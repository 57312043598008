"use client";
import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Close from '@mui/icons-material/Close';
import Translate from '@mui/icons-material/Translate';
import {logout} from "@/app/common/api";
import {useTranslation} from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import OutlinedButton from "@/app/components/OutlinedButton";
import Cameraswitch from "@mui/icons-material/Cameraswitch";
import {fallbackLng, LANGUAGE, LS_LNG_KEY} from "@/app/i18n";

const getCompanyLogoURL = () => {
  const companyData = localStorage.getItem('company')

  if (!companyData) return null;

  let parsedData = null;

  try {
    parsedData = JSON.parse(companyData)
  } catch {
    return null
  }

  return parsedData?.logo_image_url ?? null
}

export default function StoreLayout({children, onLogoClick,hasLanguageMenu, showTermsAndConditions = false, onHiddenBoxClick}: any) {
  const [termsAndConditionDialog, setTermsAndConditionDialog] = useState(false);

  const openTermsAndConditionDialog = () => {
    setTermsAndConditionDialog(true)
  }

  const closeTermsAndConditionDialog = () => {
    setTermsAndConditionDialog(false)
  }

  const {t,i18n} = useTranslation();


  const companyLogoURL = getCompanyLogoURL()

  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  
  useEffect(() => {
    const initLng = localStorage.getItem(LS_LNG_KEY) ?? fallbackLng;
    handleSelectLanguage(initLng)
  }, [])
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  }
  const setBodyDirection = (lng: string) => {
    document.body.classList.remove('rtl');
    document.body.classList.remove('ltr');

    const dir = lng === LANGUAGE.HE ? 'rtl' : 'ltr';

    document.body.classList.add(dir);
  }
  const handleSelectLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem(LS_LNG_KEY, lng);
    setSelectedLanguage(lng);
    setBodyDirection(lng);
    handleClose();
  };

  return (
    <Box sx={{
      backgroundImage: 'url(https://storage.gonilabs.com/assets/store_background.png) !important',
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      display: "flex",
      backgroundColor: 'transparent',
      position: 'absolute',
      height: '100vh',
      width: '100vw',
      zIndex: '800',
    }}
    >


      <Box sx={{
        zIndex: 1000,
        position: 'absolute',
        left: '50px',
        top: '40px',
        display: hasLanguageMenu ? 'flex' : 'none',
        flexDirection: 'column',
        alignItems: 'center',

      }}>

        <IconButton
          onClick={handleClick}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          sx={{
            svg: {
              width: '84px',
              height: '84px',
            }
          }}>
          <Translate/>
        </IconButton>
      </Box>


      <Menu
        id="basic-menu"
        sx={{}}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            '.MuiMenuItem-root': {
              fontSize: '42px',
              height: '90px'
            }
          }
        }}
      >
        <MenuItem selected={selectedLanguage === LANGUAGE.HE} onClick={() => handleSelectLanguage(LANGUAGE.HE)}>&#127470;&#127473; Hebrew</MenuItem>
        <MenuItem selected={selectedLanguage === LANGUAGE.EN} onClick={() => handleSelectLanguage(LANGUAGE.EN)}>&#127468;&#127463; English</MenuItem>
      </Menu>

      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          zIndex: 9999,
          // padding: '40px 0',
          // maxHeight: '150px',
          left: '50%',
          top: '40px',
          transform: 'translate(-50%, 0)',
          img: {
            height: '100%',
            maxHeight: '130px !important',
            width: 'auto !important',
            objectFit: 'contain'
          }
        }}
      >

        {!!companyLogoURL && <img
					onClick={onLogoClick}
					src={companyLogoURL}
					alt="Company logo"
				/>}
      </Box>

      {children}

      {showTermsAndConditions && <Box sx={{
        position: 'absolute',
        fontSize: '2rem',
        width: '100%',
        textAlign: 'center',
        mt: 4,
        bottom: '20px',
        zIndex: 9999
      }}>
				<Button variant="text" onClick={openTermsAndConditionDialog}>{t('termsAndConditions')}</Button>
			</Box>}


      <Dialog
        maxWidth="md"
        open={termsAndConditionDialog}
        onClose={closeTermsAndConditionDialog}

        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: 'rgba(19, 19, 19, 0.9)', color: '#fff', padding: '110px 40px 60px 40px'
          }
        }}
      >

        <IconButton onClick={closeTermsAndConditionDialog} sx={{
          padding: '10px!important',
          position: 'absolute',
          top: '20px',
          right: '40px',
          zIndex: 9,
          svg: {
            fill: '#fff',
            width: '64px',
            height: '64px',
          },
        }}>
          <Close/>
        </IconButton>

        <DialogTitle className="dir" sx={{
          fontSize: '3rem !important'
        }}>
          {t('termsAndConditionsTitle')}
        </DialogTitle>
        <DialogContent>
          <Box
            className={'dir'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
              fontSize: '2rem',
            }}
          >
            {t('termsAndConditionsContent')}
          </Box>
        </DialogContent>
      </Dialog>

      {!!onHiddenBoxClick && <Box onClick={onHiddenBoxClick} sx={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: '200px',
        height: '200px',
        backgroundColor: 'transparent',
        zIndex: 99999
      }}/>}
    </Box>
  )
};
