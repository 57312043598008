"use client";
import React, {useState, useEffect, useRef} from 'react';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';

import FlashOn from '@mui/icons-material/FlashOn';
import FlashOff from '@mui/icons-material/FlashOff';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Cameraswitch from '@mui/icons-material/Cameraswitch';
import CircularProgress from '@mui/material/CircularProgress';
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import {Camera} from "react-camera-pro";
import {useTheme} from "@mui/material";
import Webcam from "react-webcam";
import {wait, rotateBase64Image} from "@/app/utils";
import TextButton from "@/app/components/TextButton";

import {shortcutPhotoFemale} from "@/app/components/shortcutPhotoFemale";
import {shortcutPhotoMale} from "@/app/components/shortcutPhotoMale";
import {useTranslation} from "react-i18next";


const COUNTDOWN_DURATION_SEC = 3;

export default function TakePhotoCountDown({countDownOpen, handleSubjectPhotos}: any) {
  const cameraRef = useRef(null);
  const [countdown, setCountdown] = useState(COUNTDOWN_DURATION_SEC);
  const [countdownInterval, setCountdownInterval] = useState<NodeJS.Timeout>();
  const [cameraPreviewOn, setCameraPreviewOn] = useState(false);
  const [cameraFlashOn, setCameraFlashOn] = useState(localStorage.getItem('isFlashEnabled') === 'true');
  const theme = useTheme();
  const webcamRef = useRef(null);
  const [isFlashOn, setIsFlashOn] = useState(false);

  const { t } = useTranslation();

  const startCountdown = () => {
    if (countdownInterval) return;

    let count = countdown;
    const interval = setInterval(() => {
      count--;
      setCountdown(count);
      if (count === 0) {
        takePhotos();
        clearInterval(interval);
        setCountdownInterval(undefined);
      }
    }, 1000);

    setCountdownInterval(interval)
  }

  useEffect(() => {
    return () => {
      clearInterval(countdownInterval);
    }
  }, [])

  const takePhotos = async () => {
    const isFlashEnabled = localStorage.getItem('isFlashEnabled') === 'true';

    setIsFlashOn(isFlashEnabled);

    if (!webcamRef.current) {
      setIsFlashOn(false);
      return;
    }

    await wait(300)


    // @ts-ignore
    const photo1 = webcamRef.current.getScreenshot() as string;
    const rotatedPhoto1Promise = rotateBase64Image(photo1)

    await wait(300)

    // @ts-ignore
    const photo2 = webcamRef.current.getScreenshot() as string;
    const rotatedPhoto2Promise = rotateBase64Image(photo2)

    await Promise.all([rotatedPhoto1Promise, rotatedPhoto2Promise]).then((photos: any) => {
      handleSubjectPhotos(photos)
    }).catch(err => {
      console.log('rotateBase64Image: error when rotating images', err)
    }).finally(() => {
      setIsFlashOn(false);
    })
  }

  const handleShortcutFlow = () => {
    handleSubjectPhotos([shortcutPhotoFemale,shortcutPhotoMale])
  }

  return (
    <Box>
      <Box
        sx={{
          display: isFlashOn ? 'block' : 'none',
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          zIndex: 9999,
          backgroundColor: '#FFF',
        }}
      />

      <Box sx={{
        position: 'fixed',
        zIndex: -9999,
        opacity: 0,
        video: {
          maxWidth: 'unset !important',
        }
      }}>

        <Webcam
          screenshotQuality={1}
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={{
            width: {ideal: 3840},
            height: {ideal: 2160},
            frameRate: {ideal: 60},
            facingMode: 'user',
          }}
        />

      </Box>

      <Slide in={cameraPreviewOn}>

        <Box sx={{
          width: '100vw',
          height: '280px',
          backgroundColor: 'rgba(0,0,0,0.5)',
          position: 'absolute',

        }}/>
      </Slide>

      <IconButton
        onClick={() => setCameraPreviewOn(!cameraPreviewOn)}
        sx={{
          zIndex: 1000,
          position: 'absolute',
          right: '50px',
          top: '40px',
          svg: {
            color: !cameraPreviewOn ? '#fff' : theme.palette.secondary.main,
            width: '84px',
            height: '84px',
          }
        }}>

        <Cameraswitch/>
      </IconButton>

      <IconButton
        onClick={() => {
          localStorage.setItem('isFlashEnabled', String(!cameraFlashOn));
          setCameraFlashOn(!cameraFlashOn)
        }}
        sx={{
          zIndex: 1000,
          position: 'absolute',
          right: '50px',
          top: '170px',
          svg: {
            color: !cameraFlashOn ? '#fff' : theme.palette.secondary.main,
            width: '84px',
            height: '84px',
          }
        }}>

        {cameraFlashOn ? <FlashOn/> : <FlashOff/>}
      </IconButton>

      <Fade in={cameraPreviewOn}>
        <Box
          sx={{
            position: 'absolute',
            zIndex: -1,
            width: '100vw',
            height: '100vh',
            transition: '0.6s',
            // todo reverse
            // filter: `blur(${countdownInterval ? 0 : '10px'})`
          }}
        >
          {/*// @ts-ignore*/}
          <Camera ref={cameraRef}/>
        </Box>
      </Fade>


      <Box
        onClick={startCountdown}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <CircularProgress
          variant="determinate"
          sx={{
            color: '#fff',
            'circle': {
              strokeWidth: 1,
            }
          }}
          size={400}
          value={100}
        />

        {!!countdownInterval && <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '9rem',
          display: 'flex',
        }}>

					<Zoom in key={countdown}>
						<span>{countdown}</span>
					</Zoom>
				</Box>}

        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          <Zoom in={!countdownInterval}>
            <PlayArrow sx={{
              width: '200px',
              height: '200px',

            }} width={50}/>
          </Zoom>
        </Box>
        {!countdownInterval && <Box sx={{
          position: 'absolute',
          fontSize: '4rem',
          width: '100%',
          textAlign: 'center',
          mt: 4
        }}>{t('clickToMeasure')}</Box>}

        {!!countdownInterval && (
          <Box sx={{
            position: 'absolute',
            fontSize: '4rem',
            width: '100%',
            textAlign: 'center',
            mt: 4
          }}>
            {t('photoCountdown', {countdown})}
          </Box>
        )}
      </Box>

      <TextButton
        sx={{
          position: 'absolute',
          bottom: '70px',
          left: '50%',
          transform: 'translate(-50%, 0)'
        }}
        onClick={handleShortcutFlow}
      >
        Quick flow
      </TextButton>
    </Box>

  )
};



